<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "training-reflection-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card" style="overflow-x: auto">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Module Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-7"
                      v-if="
                        hasPermissions([
                          'trainer-training-reflection-create',
                          'trainer-training-reflection-edit',
                        ])
                      "
                    >
                      <v-select
                        :disabled="currentRouteName === 'training-reflection-update'"
                        @option:selected="getSelectedModule($event)"
                        v-model="selectedModule"
                        label="module_name"
                        :options="filterModule"
                        :selectable="(options) => isSelectableModule(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedModule.$error || errorFor('module name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('module name')"
                        :vuelidateErrors="{
                          errors: v$.selectedModule.$errors,
                          value: 'Module Name',
                        }"
                      ></v-errors>
                    </div>
                    

                    <div
                      class="col-md-7"
                      v-else-if="
                        hasPermissions(['trainer-training-reflection-list'])
                      "
                    >
                      <v-select
                        v-model="selectedModule"
                        label="module_name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Training Date
                      <span class="text-danger">*</span>
                    </label>

                    <div class="col-md-7">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="training_date"
                      />
                      </div>
                      </div>
                </div>


                <div class="col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-7"
                      v-if="
                        hasPermissions([
                          'trainer-training-reflection-create',
                          'trainer-training-reflection-edit',
                        ])
                      "
                    >
                      <v-select
                        :disabled="currentRouteName === 'training-reflection-update'"
                        @option:selected="getSelectedBatch($event)"
                        v-model="selectedBatch"
                        label="batch"
                        :options="batches"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('batch name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('batch name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-7"
                      v-else-if="
                        hasPermissions([
                          'trainer-training-reflection-list',
                        ])
                      "
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch"
                        :clearable="false"
                        disabled 
                      >
                      </v-select>
                    </div>

                  </div>
                  <div class="row mt-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Trainers
                      <span class="text-danger">*</span>
                    </label>

                    <div
                      class="col-md-7"
                      v-if="
                        hasPermissions([
                          'trainer-training-reflection-create',
                          'trainer-training-reflection-edit',
                        ])
                      "
                    >
                      <v-select
                        :disabled="currentRouteName === 'training-reflection-update'"
                        v-model="selectedTrainer"
                        label="name"
                        multiple
                        :options="trainerList"
                        :selectable="(option) => !selectedTrainer?.some(trainer => trainer.employee_id === option.employee_id)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedTrainer.$error || errorFor('trainer name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('trainer name')"
                        :vuelidateErrors="{
                          errors: v$.selectedTrainer.$errors,
                          value: 'Trainer Name',
                        }"
                      ></v-errors>
                    </div>

                  </div>
                </div>
              </div>

              <div
                class="progress mt-3 w-100"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div style="width: 1100px">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Employee ID</th>
                      <th
                        scope="col"
                        v-for="(criteria, index) in criterias"
                        :key="index"
                      >
                        {{ criteria.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(employee, index) in attendanceEmployee"
                      :key="index"
                    >
                      <td class="pt-3" scope="row">{{ ++index }}</td>
                      <td class="pt-3">{{ employee.employee_name }}</td>
                      <td class="pt-3">{{ employee.employee_id }}</td>
                      <template v-if="hasPermissions(['trainer-training-reflection-create', 'trainer-training-reflection-edit'])">
                        <td v-for="(criteria, i) in employee.criterias" :key="i">
                        <v-select
                          class="selectScore"
                          v-model="criteria.selectedScore"
                          :options="scoreOptions(criteria)"
                          :clearable="false"
                        ></v-select>
                      </td>
                      </template>
                      <template v-else-if="hasPermissions(['trainer-training-reflection-list'])">
                        <td v-for="(criteria, i) in employee.criterias" :key="i">
                        <v-select
                          class="selectScore"
                          v-model="criteria.selectedScore"
                          disabled
                          :clearable="false"
                        ></v-select>
                      </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row" v-if="hasPermissions(['trainer-training-reflection-create', 'trainer-training-reflection-edit'])">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'training-reflection' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'training-reflection-update'
                          ? updateTrainingReflection()
                          : createTrainingReflection()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { options } from "dropzone";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      employees: this.$store.getters["odoo/getAllEmployees"],
      trainerList: [],
      moduleData: [],
      trainingReflection: [],
      batches: [],
      attendanceEmployee: [],
      criterias: [],
      filterModule: [],
      training_date: null,
      selectedModule: null,
      selectedBatch: null,
      selectedTrainer: [],
      trainers: "",
      isLoading: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      selectedModule: { required },
      selectedBatch: { required },
      selectedTrainer: { required },
    };
  },
  methods: {
    isSelectableModule(options) {
      if (!this.selectedModule || !options) return true;
      return this.selectedModule.module_id != options.module_id;
    },
    isSelectableBatch(options) {
      const isUsed = this.trainingReflection?.some(
        (val) => val?.batch_name == options?.batch
      );
      return !isUsed;
    },
    async getAllModuleData() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/get-all-modules`)
        .then((response) => {
          this.moduleData = response.data.data;
          this.filterModule = this.filteredModuleData();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          this.loading = false;
          this.$Progress.finish();
        });
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    getSelectedModule(val) {
      this.batches = [];
      this.selectedBatch = null;
      this.training_date = null;
      this.selectedTrainer = null;
      this.trainers = "";
      this.attendanceEmployee = [];
      this.criterias = [];
      this.selectedTrainer = [];
      this.selectedModule = val;
      this.batches = this.moduleData.filter(
        (item) => item.module_id == val.module_id
      );
    },

    getSelectedBatch(val) {
      this.attendanceEmployee = [];
      this.criterias = [];
      val.criteria.forEach((criteria) => {
        criteria["selectedScore"] = null;
      });

      this.training_date = val.from_date + " to " + val.to_date;

      let filterTrainers = []
      val.trainers?.forEach((value_trainer) => {
          this.trainerList?.forEach((trainer) => {
            if(value_trainer.employee_id === trainer.employee_id) {
              filterTrainers.push(trainer)
            }
          })
      })

      if(filterTrainers) this.selectedTrainer = filterTrainers;

      this.employees.forEach((employee) => {
       
        val.attendances.forEach((attendance) => {
          if (employee.emp_id == attendance.employee_id) {
            attendance["employee_name"] = employee.name;
            const criteriaClone = JSON.parse(JSON.stringify(val.criteria));
            attendance[`criterias`] = criteriaClone;
          }
        });
      });

      this.attendanceEmployee = val.attendances;
      this.criterias = val.criteria;
    },

    filteredModuleData() {
      const uniqueModuleData = this.moduleData.reduce(
        (accumulator, current) => {
          const isDuplicate = accumulator.some(
            (module) => module.module_id === current.module_id
          );

          if (!isDuplicate) {
            accumulator.push(current);
          }

          return accumulator;
        },
        []
      );

      return uniqueModuleData;
    },

    async createTrainingReflection() {

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      let reflection_criterias = [];
      let hasError = false;
      this.attendanceEmployee.forEach((item) => {
        if (item.criterias.length == 0){
          return;
        }
        item.criterias.forEach((criteria) => {

          if(!criteria.selectedScore){
            hasError = true
          }

          reflection_criterias.push({
            attendance_id: item.id,
            reflection_criteria_id: criteria.id,
            score: criteria.selectedScore,
          });

        });
      });

      if(hasError) return this.toast.error("Please fill all input score.");

      if (reflection_criterias.length == 0){
          this.toast.error("Sorry, add Reflection Criterias For this module first.");
          return;
        }

      let toSendData = {
        module_id: this.selectedModule.module_id,
        module_name: this.selectedModule.module_name,
        batch_name: this.selectedBatch.batch,
        trainers: this.selectedTrainer,
        reflection_criterias: reflection_criterias,
        training_date: this.training_date
      };
      this.isLoading = true;
      await axios
        .post(`${this.baseUrl}admin/v2/training-reflection`, toSendData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$router.push({ name: "training-reflection" });
          this.toast.success("Training Reflection Successfully created.");
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },

    async updateTrainingReflection() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      let reflection_criterias = [];
      let hasError = false;
      // console.log(this.attendanceEmployee);
      this.attendanceEmployee.forEach((item) => {
        if (item.criterias.length == 0) return;
        item.criterias.forEach((criteria) => {

          if(!criteria.selectedScore){
            hasError = true
          }
          
          reflection_criterias.push({
            attendance_id: item.id ? item.id : item.attendance_id,
            reflection_criteria_id: criteria.id,
            score: criteria.selectedScore,
          });
        });
      });

      if(hasError) return this.toast.error("Please fill all input score.");

      if (reflection_criterias.length == 0){
        this.toast.error("Sorry, add Reflection Criterias For this module first.");
        return;
      }
      let toSendData = {
        module_id: this.selectedModule.module_id
          ? this.selectedModule.module_id
          : this.selectedModule,
        module_name: this.selectedModule.module_name
          ? this.selectedModule.module_name
          : this.selectedModule,
        batch_name: this.selectedBatch.batch
          ? this.selectedBatch.batch
          : this.selectedBatch,
        trainers: this.selectedTrainer,
        reflection_criterias: reflection_criterias,
        training_date: this.training_date
      };
      this.isLoading = true;

      await axios
        .put(
          `${this.baseUrl}admin/v2/training-reflection/${this.$route.params.id}`,
          toSendData,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "training-reflection" });
          this.toast.success("Training Reflection Successfully updated.");
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },

    async getTrainingReflectionById() {
      this.loading = false;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/training-reflection/${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data;
          this.selectedModule = {
            module_id: data.module_id,
            module_name: data.module_name,
          };

          this.training_date = data.training_date;
          this.selectedBatch = data.batch_name;
          this.selectedTrainer = data.trainers;
          this.trainers = data.trainers.map((item) => {
            return item.employee_name;
          });

          this.attendanceEmployee = data.reflection_scores;

          this.employees.forEach((employee) => {
            this.attendanceEmployee.forEach((attendance) => {
              if (employee.emp_id == attendance.employee_id) {
                attendance["employee_name"] = employee.name;
                const criteriaClone = JSON.parse(
                  JSON.stringify(attendance.reflection_criterias)
                );
                attendance[`criterias`] = criteriaClone;
              }
            });
          });
          this.criterias =
            data.reflection_scores[0].reflection_criterias;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },

    async getTrainingRelection() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/training-reflection`)
        .then((response) => {
          this.trainingReflection = response.data.data;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },

    async getAllTrainers() {
      await axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainerList = response.data.data;
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
        });
    }
  },
  computed: {
    
    currentRouteName() {
      return this.$route.name;
    },
    
  },

  created() {
    this.getAllTrainers();
    this.getTrainingRelection();
    if (this.currentRouteName == "training-reflection-update") {
      this.getTrainingReflectionById();
    }
    this.getAllModuleData();
  },
};
</script>
<style scoped>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
/* .trainer-form {
  width: 205px;
  height: 33px;
  margin-left: 5px;
} */
.box {
  max-width: 1000px;
  /* overflow-x: auto; */
}
.box-width {
  width: 240px;
}
/* .selectScore {
  padding: 0.45rem 0.9rem;
} */
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset;
  border-style: unset;
  border-width: unset;
}
</style>
